import { useTranslation } from '@pancakeswap/localization'
import { Currency, CurrencyAmount, Pair, Percent, Token } from '@pancakeswap/sdk'
import { WrappedTokenInfo } from '@pancakeswap/token-lists'
import { ArrowDropDownIcon, Button, CopyButton, Flex, Loading, Skeleton, Text, useModal } from '@pancakeswap/uikit'
import { formatAmount } from '@pancakeswap/utils/formatFractions'
import { DoubleCurrencyLogo, Swap as SwapUI } from '@pancakeswap/widgets-internal'
import { memo, useCallback, useMemo } from 'react'
import { styled } from 'styled-components'
import { safeGetAddress } from 'utils'

import { formatNumber } from '@pancakeswap/utils/formatBalance'
import { useStablecoinPriceAmount } from 'hooks/useBUSDPrice'
import { StablePair } from 'views/AddLiquidity/AddStableLiquidity/hooks/useStableLPDerivedMintInfo'

import CurrencyLogo, { FiatLogo } from 'components/Logo/CurrencyLogo'
import { useCurrencyBalance } from 'state/wallet/hooks'
import { useAccount } from 'wagmi'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'

import AddToWalletButton from '../AddToWallet/AddToWalletButton'

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  /* padding: ${({ selected }) => (selected ? '0.75rem 0.75rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')}; */
  padding: 0;
`
const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm' })`
  padding: 0px;
`

const MaxPosition = styled.div<{ selected: boolean }>`
  /* position: absolute;
  top: 0;
  right: 0;
  transform: ${({ selected }) =>
    selected ? 'translate(-12px, calc(50% + 1px))' : 'translate(-12px, calc(50% + 0px))'}; */
`

interface CurrencyInputPanelProps {
  value: string | undefined
  onUserInput: (value: string) => void
  onInputBlur?: () => void
  onPercentInput?: (percent: number) => void
  onMax?: () => void
  showQuickInputButton?: boolean
  showMaxButton?: boolean
  maxAmount?: CurrencyAmount<Currency>
  lpPercent?: string
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | StablePair | null
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  commonBasesType?: string
  showSearchInput?: boolean
  beforeButton?: React.ReactNode
  disabled?: boolean
  error?: boolean | string
  showUSDPrice?: boolean
  tokensToShow?: Token[]
  currencyLoading?: boolean
  inputLoading?: boolean
  title?: React.ReactNode | string
  hideBalanceComp?: boolean
}
const CurrencyInputPanel = memo(function CurrencyInputPanel({
  value,
  onUserInput,
  onInputBlur,
  onPercentInput,
  onMax,
  showQuickInputButton = false,
  showMaxButton,
  maxAmount,
  lpPercent,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  beforeButton,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases,
  commonBasesType,
  showSearchInput,
  disabled,
  error,
  showUSDPrice,
  tokensToShow,
  currencyLoading,
  inputLoading,
  title,
  hideBalanceComp,
}: CurrencyInputPanelProps) {
  const { address: account } = useAccount()

  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const { t } = useTranslation()

  const mode = id
  const token = pair ? pair.liquidityToken : currency?.isToken ? currency : null
  const tokenAddress = token ? safeGetAddress(token.address) : null

  const amountInDollar = useStablecoinPriceAmount(
    showUSDPrice ? currency ?? undefined : undefined,
    value !== undefined && Number.isFinite(+value) ? +value : undefined,
    {
      hideIfPriceImpactTooHigh: true,
      enabled: Boolean(value !== undefined && Number.isFinite(+value)),
    },
  )

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
      commonBasesType={commonBasesType}
      showSearchInput={showSearchInput}
      tokensToShow={tokensToShow}
      mode={mode}
    />,
  )

  const percentAmount = useMemo(
    () => ({
      25: maxAmount ? maxAmount.multiply(new Percent(25, 100)).toExact() : undefined,
      50: maxAmount ? maxAmount.multiply(new Percent(50, 100)).toExact() : undefined,
      75: maxAmount ? maxAmount.multiply(new Percent(75, 100)).toExact() : undefined,
    }),
    [maxAmount],
  )

  const handleUserInput = useCallback(
    (val: string) => {
      onUserInput(val)
    },
    [onUserInput],
  )

  const onCurrencySelectClick = useCallback(() => {
    if (!disableCurrencySelect) {
      onPresentCurrencyModal()
    }
  }, [onPresentCurrencyModal, disableCurrencySelect])

  const isAtPercentMax = (maxAmount && value === maxAmount.toExact()) || (lpPercent && lpPercent === '100')

  const balance = !hideBalance && !!currency ? formatAmount(selectedCurrencyBalance, 6) : undefined
  return (
    <SwapUI.CurrencyInputPanel
      id={id}
      disabled={disabled}
      error={error as boolean}
      value={value}
      onInputBlur={onInputBlur}
      onUserInput={handleUserInput}
      loading={inputLoading}
      top={
        <>
          {typeof title === 'string' ? <Text fontSize="12px">{title}</Text> : title}

          {account && !hideBalanceComp && (
            <Flex justifyContent="flex-end" width="100%">
              <Text
                textAlign="right"
                onClick={!disabled ? onMax : undefined}
                mr="6px"
                ellipsis
                fontSize="12px"
                style={{ display: 'inline', cursor: 'pointer' }}
              >
                {t('Balance')}:
              </Text>
              <Text
                textAlign="right"
                fontSize="12px"
                whiteSpace="nowrap"
                title={
                  !hideBalance && !!currency ? t('Balance: %balance%', { balance: balance ?? t('Loading') }) : ' -'
                }
              >
                {!hideBalance && !!currency
                  ? (balance?.replace('.', '')?.length || 0) > 12
                    ? balance ?? t('Loading')
                    : balance
                  : '-'}
              </Text>
            </Flex>
          )}
        </>
      }
      middle={
        <>
          <Flex alignItems="center" justifyContent="flex-end">
            {beforeButton ?? null}
            <CurrencySelectButton
              className="open-currency-select-button"
              selected={!!currency}
              onClick={onCurrencySelectClick}
            >
              <Flex alignItems="center" justifyContent="space-between">
                {pair ? (
                  <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
                ) : currency ? (
                  id === 'onramp-input' ? (
                    <FiatLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
                  ) : (
                    <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
                  )
                ) : currencyLoading ? (
                  <Skeleton width="24px" height="24px" variant="circle" />
                ) : null}

                {currencyLoading ? null : pair ? (
                  <Text id="pair" color="primary" bold>
                    {pair?.token0.symbol}:{pair?.token1.symbol}
                  </Text>
                ) : (
                  <Text id="pair" color="primary" bold>
                    {(currency && currency.symbol && currency.symbol.length > 10
                      ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                          currency.symbol.length - 5,
                          currency.symbol.length,
                        )}`
                      : currency?.symbol) || t('Select a currency')}
                  </Text>
                )}
                {!currencyLoading && !disableCurrencySelect && <ArrowDropDownIcon pathfill="text" />}
              </Flex>
            </CurrencySelectButton>

            {token && tokenAddress ? (
              <Flex style={{ gap: '4px' }} ml="4px" alignItems="center">
                <CopyButton
                  width="16px"
                  buttonColor="text"
                  text={tokenAddress}
                  tooltipMessage={t('Token address copied')}
                />
                <AddToWalletButton
                  variant="text"
                  p="0"
                  height="auto"
                  width="fit-content"
                  tokenAddress={tokenAddress}
                  tokenSymbol={token.symbol}
                  tokenDecimals={token.decimals}
                  tokenLogo={token instanceof WrappedTokenInfo ? token.logoURI : undefined}
                />
              </Flex>
            ) : null}
          </Flex>
        </>
      }
      bottom={
        <>
          <Flex flexDirection="row" justifyContent="space-between" style={{ gap: '12px' }}>
            {showUSDPrice ? (
              <Flex justifyContent="flex-end" mr="1rem">
                <Flex maxWidth="200px">
                  {inputLoading ? (
                    <Loading width="14px" height="14px" />
                  ) : showUSDPrice && Number.isFinite(amountInDollar) ? (
                    <Text fontSize="12px" color="text" ellipsis>
                      {`$${amountInDollar ? formatNumber(amountInDollar) : 0}`}
                    </Text>
                  ) : (
                    <Text fontSize="12px" color="text" ellipsis>
                      {`$${0}`}
                    </Text>
                  )}
                </Flex>
              </Flex>
            ) : (
              <div />
            )}

            <InputRow selected={disableCurrencySelect}>
              {account && currency && selectedCurrencyBalance?.greaterThan(0) && !disabled && (
                <Flex
                  alignItems="right"
                  justifyContent="right"
                  p={showQuickInputButton && disableCurrencySelect ? '0 12px 12px' : '0px'}
                  style={{ gap: '2px' }}
                >
                  {/* maxAmount?.greaterThan(0) && */}
                  {showQuickInputButton &&
                    onPercentInput &&
                    [25, 50, 75].map((percent) => {
                      const isAtCurrentPercent =
                        (maxAmount && value !== '0' && value === percentAmount[percent]) ||
                        (lpPercent && lpPercent === percent.toString())

                      return (
                        <Button
                          key={`btn_quickCurrency${percent}`}
                          onClick={() => {
                            onPercentInput(percent)
                          }}
                          scale="xs"
                          variant={isAtCurrentPercent ? 'primary' : 'text'}
                          style={{ textTransform: 'uppercase', padding: '0 4px' }}
                        >
                          {percent}%
                        </Button>
                      )
                    })}
                  {showMaxButton ? (
                    <MaxPosition selected={disableCurrencySelect}>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          onMax?.()
                        }}
                        scale="xs"
                        variant={isAtPercentMax ? 'primary' : 'text'}
                        style={{ textTransform: 'uppercase', padding: '0 4px' }}
                      >
                        {t('100%')}
                      </Button>
                    </MaxPosition>
                  ) : null}
                </Flex>
              )}
            </InputRow>
          </Flex>
        </>
      }
    />
  )
})

export default CurrencyInputPanel
